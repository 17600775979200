.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

code {
  white-space: normal;
  color: white;
}

pre {
  background-color: black;
}

img {
  max-width: 1300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For Loader */

.ta-loader {
  display: none;
  width: 100%;
  z-index: 10000;
  top: 0;
  height: 100vh;
  overflow: hidden;
  max-height: 100vh;
  background: rgba(255,255,255,0.2);
  position: fixed;
}

.ta-loader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-img{
  width: 220px;
}

/* For Error Messgae */

.err-class {
  font-size: 14px;
  color: #d62049;
  margin-top: 5px;
  font-style: oblique;
}
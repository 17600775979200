/* Material UI */
.accordian-container{
    padding: 40px 150px;
    /* max-width: 1200px; */
    /* padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; */
    height: auto;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    background:
      url("/images/bg-3.webp");
    background-size: cover;
}
.acc-cards{
    color: #fff;
    /* transform: translate(-50%, -50%); */
    background: rgba(0,0,0,.5);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 10px;
}
.acc-font{
    font-size: 24px !important;
    font-family: fantasy !important;
}

p {
    margin-top: 0;
    margin-bottom: 0 !important;
}

.acc-bg div{
    background: rgba(0,0,0,.5) !important;
    box-sizing: border-box !important;
    /* box-shadow: 0 15px 25px rgb(0 0 0 / 60%) !important; */
    border-radius: 10px !important;
    color: white !important;
    /* font-size: 24px !important; */
}

.heading-clr{
    color: white;
    margin-bottom: 15px;
    font-family: serif;
}

@media only screen and (max-width: 600px) {
    .accordian-container{
        padding: 30px 20px;
    }
}